import React, { useEffect, useState } from "react";
import { PrimaryBtn, Container } from 'components/library'
import { Link, graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PolymersImage from "images/applications/polymers.png"
import AutomotiveImage from "images/applications/automotiveIndustry.jpg"
import PaintImage from "images/applications/paint.jpg"
import RubberImage from "images/applications/rubber.jpg"
import FoodPackagingImage from "images/applications/foodPackaging.jpg"
import { ArrowRight } from "react-feather";
import SolutionsContent from "../solutions-page/solutionsContent";
import SolutionsNavbar from "../solutions-page/solutionsNavbar";
import SolutionsNavbarMobile from "../solutions-page/solutionsNavbarMobile"
import { InnovationIllustration } from "components/library/illustrations/innovation-illustration";
import { CtaBlock } from "components/common/cta-block";


const SolutionsPage = () => {
  const { t } = useTranslation()

  const [selectedTab, setSelectedTab] = useState('sol1'); // Default selected tab

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
  };
  const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleWidthChange = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWidthChange);
    return () => {
      window.removeEventListener("resize", handleWidthChange);
    };
    }, []);
    return width;
  };
    const windowWidth = useWindowWidth();


  const applicationsContent: any = {
    polymers: {
      name: "Polymers",
      image: PolymersImage,
      remarks: t('applications-page.remarks.polymers')
    },
    rubber: {
      name: "Rubber",
      type: "material",
      image: RubberImage,
      remarks: t('applications-page.remarks.rubber')
    },
    "paints and coatings": {
      name: "Paints and Coatings",
      image: PaintImage,
      type: "application",
      remarks: t('applications-page.remarks.paintsAndCoatings')
    },
    "automotive industry": {
      name: "Automotive",
      image: AutomotiveImage,
      type: "application",
      remarks: t('applications-page.remarks.automotiveIndustry')
    },
    "packaging industries": {
      name: "Packaging",
      image: FoodPackagingImage,
      type: "application",
      remarks: t('applications-page.remarks.packagingIndustries')
    }
  }

  return (
    <main>
      <Container className="flex flex-col gap-16 py-16">
        <section className="flex flex-row sm:justify-between sm:flex-row items-center lg:text-left min-w-full">
            <div className='min-w-full sm:w-[60%] flex flex-col gap-10'>
                <span className="text-3xl tracking-wider font-light sm:text-5xl md:text-7xl bg-gradient-to-r from-theme-blue from-0% via-theme-orange via-20% to-theme-orange to-100% bg-clip-text text-transparent">
                    {t('solutions-page.heading')}
                </span>
                <p className="text-base sm:text-lg sm:max-w-xl md:text-2xl min-w-[95%]">
                    {t('solutions-page.desc')}
                </p>
                <PrimaryBtn
                    className='md:text-lg max-w-max flex justify-between items-center gap-2'
                    onClick={() => window.requestADemo()}
                >
                    {t('common.cta.demo')}
                    <ArrowRight size={20} />
                </PrimaryBtn>
                <div className="h-[1px] sm:block shrink-0 grow bg-gray-200 mt-4" />
            </div>
        </section>

        <section className="flex flex-col sm:flex-row gap-10 w-full mb-10 sm:mb-30">
          {windowWidth > 630 ?
            <>
              <SolutionsNavbar selectedTab={selectedTab} onTabClick={handleTabClick}/>
              <div className="flex flex-col gap-16">
                <SolutionsContent selectedTab={selectedTab}/>
              </div>
            </>
          : <SolutionsNavbarMobile selectedTab={selectedTab} onTabClick={handleTabClick}/>
          }
        </section>

    <section className="md:max-h-48 sm:max-h-full bg-gradient-to-b flex justify-between p-5 lg:py-10 md:py-4 sm:py-2 lg:px-1 md:pr-5 lg:pr-5 lg:bg-gradient-to-r from-[#3C67F0] to-[#F5B581] rounded-lg">
      <div className="w-full flex lg:flex-row xl-flex-row md:flex-row sm:flex-col flex-col gap-6 text-white items-center">
            <InnovationIllustration height='150%'/>
            <span className="lg:text-3xl md:text-2xl sm:text-xl max-w-[50%] md:ml-[-50px]">{t('cta-block.title')}</span>
            <PrimaryBtn
                className='md:text-lg max-w-max flex justify-between items-center gap-2 xl:ml-[70px] md:ml-[0px]'
                onClick={() => window.requestADemo()}
            >
                {t('common.cta.demo')}
            </PrimaryBtn>
        </div>
    </section>

        <section className="flex flex-col gap-10 w-full justify-center text-center mt-10">
            <h5 className="text-xl sm:text-lg sm:max-w-xl md:text-2xl uppercase min-w-[100%] uppercase bg-gradient-to-r from-theme-orange from-0% via-theme-orange via-45% to-theme-blue to-60% bg-clip-text text-transparent">{t('applications-page.heading')}</h5>
            <p className="text-xl sm:text-lg font-regular sm:max-w-xl md:text-4xl min-w-[100%] md:px-[60px] sm:px-[20px]">
                {t('solutions-page.applications-section-desc')}
            </p>
        </section>
        <div className="grid md:grid-cols-5 w-full sm:text-left gap-12 mb-20 ">
            {Object.keys(applicationsContent).map(item => {
            return (
                <Link
                    key={item}
                    to="/applications"
                    className="flex flex-col w-full rounded-md shadow-lg gap-2 p-0 justify-center text-center hover:shadow-indigo-500/40  hover:text-blue-700"
                    >
                        <img
                            src={applicationsContent[item].image}
                            className="w-full rounded-t-lg max-h-[70%]"
                            alt={applicationsContent[item].remarks}
                        />
                        <p className="bg-white md:text-center">
                            {process.env.GATSBY_LANGUAGE_SELECTED === 'ja' ? 
                            t(`applications-page.materials.${item}`)
                              : applicationsContent[item].name}
                        </p>
                </Link>)
            })}
		</div>
      </Container>
    <CtaBlock className="md:mt-[100px] lg:mt-0" />
    </main >
  );
};

export default SolutionsPage;

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`; 